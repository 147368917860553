import H3 from '@material-tailwind/react/Heading3';
import Paragraph from '@material-tailwind/react/Paragraph';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';
import Button from '@material-tailwind/react/Button';
import LeadText from "@material-tailwind/react/LeadText";

export default function Form() {
    return (
        <div className="flex flex-wrap justify-center mt-24">
            <div className="w-full lg:w-8/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
                    <div className="flex-auto p-5 lg:p-10">
                        <div className="w-full text-center">

                            <a id="sec-ind-3"></a>

                            <H3 color="gray">
                                Kontakt
                            </H3>
                            <Paragraph color="blueGray">
                                {/*masz pytania? - chcesz zamówić usługi? - jesteś zainteresowany współpracą?*/}



                            </Paragraph>
                            <LeadText color="blueGray">
                                {/* rx@rxsoft.pl

                                +48 727 700 444
                                &#49;&#49;&#50; */}
                                <p><span class="rev email">tfosxr</span></p>
                                <p><span class="rev phone">727</span></p>
                            </LeadText>
                            <LeadText color="blueGray">
                                ul.Rapackiego 20/61
                                26-600 Radom
                               
                            </LeadText>
                            <LeadText color="blueGray">
                             NIP 9482173180
                            </LeadText>
                           
                        </div>
                        {/*<form onSubmit={(e) => e.preventDefault()}>*/}
                        {/*    <div className="flex gap-8 mt-16 mb-12">*/}
                        {/*        <Input*/}
                        {/*            type="text"*/}
                        {/*            placeholder="Full Name"*/}
                        {/*            color="lightBlue"*/}
                        {/*        />*/}
                        {/*        <Input*/}
                        {/*            type="email"*/}
                        {/*            placeholder="Email Address"*/}
                        {/*            color="lightBlue"*/}
                        {/*        />*/}
                        {/*    </div>*/}

                        {/*    <Textarea color="lightBlue" placeholder="Message" />*/}

                        {/*    <div className="flex justify-center mt-10">*/}
                        {/*        <Button color="lightBlue" ripple="light">*/}
                        {/*            Send Message*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</form>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
