import Title from 'components/landing/Title';
import ContactCard from 'components/landing/ContactCard';
import Form from 'components/landing/Form';

export default function ContactSection() {
    return (
        <section className="pb-20 relative block bg-gray-100">
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
                <a id="sec-ind-2"></a>

                <Title heading="Nasza Oferta">
                    System dostosowany do potrzeb firmy, a nigdy na odwrót
                </Title>

                <div className="flex flex-wrap -mt-12 justify-center">
                    <ContactCard icon="stars" title="Usługi">
                        Wierzymy, że to Państwo sami wiecie najlepiej jak realizować procesy sprzedażowe oraz jak prowadzić interesy ze swoimi Klientami. System komputerowy powinien Państwa w tym wspierać, podążać za Państwa firmą i w pełni dostosowywać się do procesów Państwa przedsiębiorstwa.
                        Od samego początku postawiliśmy sobie za cel tworzenie oprogramowania, które będzie pomagało naszym klientom w codziennej pracy.
                    </ContactCard>
                    <ContactCard icon="insert_chart" title="Gwarancja">
                        Wszystkie nasze rozwiązania są objęte gwarancją. Od momentu wdrożenia systemu wszystkie usterki i ukryte błędy są przez nas poprawiane całkowicie za darmo.
                    </ContactCard>
                    <ContactCard icon="launch" title="Opieka powdrożeniowa">
                        Opieka powdrożeniowa istnieje w celu zapewnienia ciągłego wsparcia technicznego po wdrożeniu systemu. W ramach umowy serwisowej dbamy o ciągłe i bezproblemowe działanie systemu oraz wprowadzamy drobne usprawnienia. Zapewniamy także gwarantowane czasy reakcji i poprawy działania systemu w ramach SLA.
                    </ContactCard>
                </div>

                <Form />
            </div>
        </section>
    );
}
