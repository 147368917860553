import Card from '@material-tailwind/react/Card';
import CardImage from '@material-tailwind/react/CardImage';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import H4 from '@material-tailwind/react/Heading4';
import H6 from '@material-tailwind/react/Heading6';
import LeadText from '@material-tailwind/react/LeadText';
import Paragraph from '@material-tailwind/react/Paragraph';
import StatusCard from 'components/landing/StatusCard';
import Teamwork from 'assets/img/teamwork.jpeg';

export default function WorkingSection() {
    return (
        <section className="pb-20 bg-gray-100 -mt-32">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap relative z-50">
                    <a id="sec-ind-1"></a>
                    <StatusCard color="red" icon="stars" title="Co robimy">
                        Zajmujemy się projektowaniem,realizacją rozwiązań informatycznych na zamówienie klienta . Działamy pod róznymi markami, pracujemy dla wielu klientów.
                        Świadczymy usługi audytu, analizy potrzeb oraz wsparcia dla firm potrzbujących specjalistów IT którzy mają wieloletnie doświadczenie w pracy w firmach produkcyjnych.
                    </StatusCard>
                    <StatusCard
                        color="lightBlue"
                        icon="autorenew"
                        title="W co wierzymy"
                    >
                        Wierzymy, że nasi Klienci najlepiej wiedzą jak prowadzić swój biznes, dlatego tworzymy oprogramowanie dostosowane do ich indywidualnych potrzeb, które wraz z nimi rozwija się i pomaga rosnąć ich organizacji.
                        Potrafimy przenieść wymagania Klienta na język informatyki, optymalizować procesy oraz zapewnić wysoką jakość rozwiązań.
                        To system ma się dopasować do Klienta, a nie odwrotnie.     
                    </StatusCard>
                    <StatusCard
                        color="teal"
                        icon="fingerprint"
                        title="Jak działamy"
                    >
                        Do każdego projektu podchodzimy indywidualnie, tak aby dostarczyć klientom rozwiązania informatyczne, które są odpowiedzą na ich realne potrzeby. Wierzymy, że tylko takie podejście pozwala stworzyć oprogramowanie, które stanowi realne wsparcie dla rozwoju organizacji naszych Klientów.
                    </StatusCard>
                </div>

                <div className="flex flex-wrap items-center mt-32">
                    <div className="w-full md:w-5/12 px-4 mx-auto">
                        <div className="text-blue-gray-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                            <Icon name="people" size="3xl" />
                        </div>
                        <H4 color="gray">Praca z nami </H4>
                        <LeadText color="blueGray">
                            Potrzeby klienta stawiamy ponad wszystko. Każdy nasz system jest jak żywy organizm, który dostosowuje się do swojego właściciela. Dlatego zapewniamy naszym klientom wsparcie pozwalające usprawniać i modyfikować oprogramowanie tak, aby dostosowywało się do zmieniających się potrzeb i warunków rynku.
                        </LeadText>
                        {/*<LeadText color="blueGray">*/}
                        {/*    The kit comes with three pre-built pages to help you*/}
                        {/*    get started faster. You can change the text and*/}
                        {/*    images and you're good to go. Just make sure you*/}
                        {/*    enable them first via JavaScript.*/}
                        {/*</LeadText>*/}
                        <a
                            href="#pablo"
                            className="font-medium text-light-blue-500 mt-2 inline-block"
                        >
                            {/*Read More*/}
                        </a>
                    </div>

                    <div className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
                        {/*<Card>*/}
                        {/*    <CardImage alt="Card Image" src={Teamwork} />*/}
                        {/*    <CardBody>*/}
                        {/*        <H6 color="gray">Klient </H6>*/}
                        {/*        <Paragraph color="blueGray">*/}
                        {/*            Potrzeby klienta stawiamy ponad wszystko. Każdy nasz system jest jak żywy organizm, który dostosowuje się do swojego właściciela. Dlatego zapewniamy naszym klientom wsparcie pozwalające usprawniać i modyfikować oprogramowanie tak, aby dostosowywało się do zmieniających się potrzeb i warunków rynku.*/}
                        {/*        </Paragraph>*/}
                        {/*    </CardBody>*/}
                        {/*</Card>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}
